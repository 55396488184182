import {KeyboardEvent, useEffect, useMemo, useRef, useState} from 'react';
import {inTryCatch} from '../../lib';
import {
  DbResponseResultRow,
  PagingParams,
  RequestDistrictDataDao,
  QueryRequestDistrictFilters, SortingInfo,
  Endemicity, SessionData, RequestDistrictSortInfo
} from '../../api';
import {useAppContext} from '../../context';
import {
  DangerAlert, 
  GenericSearchTableHeader, 
  GenericSearchTableHeaderParams, 
  Pager, 
  SortTableHeader, 
  GenericSelectTableHeader, 
  GenericSelectTableHeaderParams,
  IntegerView
} from '../../components';
import {uniqueId} from 'lodash';


interface District {
  region_name: string
  subregion_name?: string
  district_name: string
  id: number
  full_name: string
}


function SearchTableHeader(p: GenericSearchTableHeaderParams<QueryRequestDistrictFilters>) { return GenericSearchTableHeader<QueryRequestDistrictFilters>(p)}
function SelectTableHeader(p: GenericSelectTableHeaderParams<QueryRequestDistrictFilters>) { return GenericSelectTableHeader<QueryRequestDistrictFilters>(p)}





function EditableDistrictTh(
  {
    row,
    session,
  } : {
    row: DbResponseResultRow
    session: SessionData
  }
): JSX.Element {


  const inputTag = useRef<HTMLInputElement | null>(null)

  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedName, setSelectedName] = useState('')
  const [error, setError] = useState<string>()
  const [districtOptions, setDistrictOptions] = useState<District[]>([])

  const requestId = +(row.request_id ?? 0)

  useEffect(() => {
    const tag = inputTag.current
    if (tag !== null)
      tag.focus({preventScroll: true})
  }, [editMode])

  useEffect(inTryCatch(setLoading, setError, async (state) => {
    const rows = await new RequestDistrictDataDao(session).queryDistricts({requestId, filter: selectedName})
    if (!state.mounted) return
    setDistrictOptions(rows.map(r => ({
      region_name: r.region_name as string,
      subregion_name: r.subregion_name as (string | null) ?? undefined,
      district_name: r.district_name as string,
      full_name: r.full_name as string,
      id: +(r.id ?? 0)
    })))
  }), [session, requestId, selectedName])

  const selectedDistrictId = useMemo<number>(() => {
    return +(districtOptions.filter(d => d.full_name === selectedName)[0]?.id ?? 0)
  }, [districtOptions, selectedName])

  function save() {
    (async () => {
      try {
        setSaving(true)

        await new RequestDistrictDataDao(session).assignDistrict(+(row.id ?? 0), requestId, selectedDistrictId)
        const foundDistrict = districtOptions.filter(d => d.id === selectedDistrictId)[0]
        row.region_name = foundDistrict.region_name
        row.subregion_name = foundDistrict.subregion_name ?? null
        row.district_name = foundDistrict.district_name
        row.district_id = selectedDistrictId
        setEditMode(false)

      }
      finally {
        setSaving(false)
      }

    })().catch(e => setError(e.message))
  }

  const inputId = useMemo(() => uniqueId('input_'), [])
  const dataListId = useMemo(() => uniqueId('dataListId_'), [])

  if (editMode) {
    return (
      <td>
        <div style={{minWidth: '200px'}}>
          <input id={inputId} className="form-control form-control-sm mt-1 mb-2" list={dataListId} value={selectedName}  onChange={e => setSelectedName(e.target.value)} ref={inputTag} />
          <datalist id={dataListId}>
            {districtOptions.map(d => (<option key={d.id}>{d.full_name}</option>))}
          </datalist>
          <div className="d-flex align-items-center">
            <button type="button" className="btn btn-primary btn-tiny" onClick={save} disabled={selectedDistrictId === 0}>Save</button>
            <button type="button" className="btn btn-tiny" onClick={() => setEditMode(false)}>Cancel</button>
            <div className="flex-fill"></div>
            {saving &&
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }
          </div>
          <DangerAlert text={error} className="mt-2" />
        </div>
      </td>
    )
  } else {
    return (
      <td style={{whiteSpace: 'nowrap'}}>
        <div className="btn-group" role="group">
        {formatRequestDistrictButtons(row, () => {
            setEditMode(true); 
            setError(undefined); 
            setSelectedName(formatOriginalRequestDistrictName(row));
        })}
        </div>
      </td>
    )
  }
}


function formatOriginalRequestDistrictName(row: DbResponseResultRow): string {
  return [row.jrsm_region_name, row.jrsm_district_name].filter(s => !!s).join(' -> ')
}

function formatRequestDistrictName(row: DbResponseResultRow): string {
  if (!!row.district_id) {
    if (!!row.subregion_name)
      return `${row.region_name} -> ${row.subregion_name} -> ${row.district_name}`

    return `${row.region_name} -> ${row.district_name}`
  }

  return formatOriginalRequestDistrictName(row)
}

function formatRequestDistrictButtons(
  row: DbResponseResultRow, 
  onClick: () => void
): JSX.Element {
  if (row.district_id) {
    let els = [
      <button type="button" className="btn btn-tiny btn-outline-secondary" onClick={onClick}>
        {row.region_name}
      </button>
    ];
    if (row.subregion_name) {
      els.push(
        <button type="button" className="btn btn-tiny btn-outline-secondary" onClick={onClick}>
          {row.subregion_name}
        </button>
      )
    }
    els.push(
      <button type="button" className="btn btn-tiny btn-outline-success" onClick={onClick}>
        {row.district_name}
      </button>
    )
    return <>{els}</>;
  } else {
    return (
      <button type="button" className="btn btn-tiny btn-outline-danger" onClick={onClick}>
        {[row.jrsm_region_name, row.jrsm_district_name].filter(s => !!s).join(' | ')}
      </button>
    )
  }
}


export function RequestDistricts(
  {
    requestId,
  } : {
    requestId: number
  }
): JSX.Element {

  const { session } = useAppContext()

  const [requestDistricts, setRequestDistricts] = useState<DbResponseResultRow[]>()
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  // const [districts, setDistricts] = useState<District[]>([])

  const [filters, setFilters] = useState<QueryRequestDistrictFilters>({district: ''})
  const [paging, setPaging] = useState<PagingParams>({
    limit: 100,
    offset: 0,
    sort: 'district',
    order: 'asc'
  })


  function changeFilter(prop: keyof QueryRequestDistrictFilters, val: string) {
    setFilters(prev => {
      const next = {...prev}
      next[prop] = val
      return next
    })
    setPaging(p => ({
      ...p,
      offset: 0
    }))
  }

  function changeSort(prop: string) {
    const newDirection = paging.sort === prop
      ? (
        paging.order === 'asc' ? 'desc' : 'asc'
      ) : 'asc'

    setPaging(prev => {
      return {
        ...prev,
        order: newDirection,
        sort: prop,
        offset: 0
      }
    })
  }

  useEffect(inTryCatch(setLoading, setError, async (state) => {
    const req = await new RequestDistrictDataDao(session).query(requestId, filters, paging)
    if (!state.mounted) return
    setRequestDistricts(req.rows)
    setCount(req.count)
  }), [session, requestId, filters, paging])




  return (
    <div className="card-body">
      <DangerAlert text={error} />
      <div style={{overflowX: 'auto'}}>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <SearchTableHeader title="District" prop='district'
                rowSpan={2}
                setFilter={changeFilter} paging={paging} 
                changeSort={changeSort} sortInfo={RequestDistrictSortInfo} 
              />
              <th colSpan={3}>Population</th>
              <SelectTableHeader title="LF End" prop='endemicity_lf' rowSpan={2} 
                setFilter={changeFilter} paging={paging} changeSort={changeSort} 
                sortInfo={RequestDistrictSortInfo} options={Endemicity} 
              />
              <SelectTableHeader title="Oncho End" prop='endemicity_oncho' rowSpan={2}
                setFilter={changeFilter} paging={paging} changeSort={changeSort} 
                sortInfo={RequestDistrictSortInfo} options={Endemicity} 
              />
              <th colSpan={2}># Rounds</th>
              <th colSpan={2}># Surveys</th>
              <SortTableHeader title="Oncho Village Target Pop." prop='target_pop_village_oncho'  rowSpan={2}
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo}
              />
              <SortTableHeader title="Stock Rem." prop='ivm_stock_remaining'  rowSpan={2}
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo} 
              />
            </tr>
            <tr>
              <SortTableHeader title="PreSAC" prop='population_presac' 
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo} 
              />
              <SortTableHeader title="SAC" prop='population_sac' 
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo} 
              />
              <SortTableHeader title="Adult" prop='population_adult' 
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo} 
              />
              <SortTableHeader title="LF" prop='rounds_lf'
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo}
              />
              <SortTableHeader title="Oncho" prop='rounds_oncho' 
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo}
              />
              <SortTableHeader title="LF" prop='surveys_lf_tas' 
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo} 
              />
              <SortTableHeader title="Oncho" prop='surveys_oncho' 
                paging={paging} changeSort={changeSort} sortInfo={RequestDistrictSortInfo}
              />
            </tr>
          </thead>
          <tbody>
          {
            requestDistricts?.map(row => (
              <tr key={row.id?.toString()}>
                <EditableDistrictTh row={row} session={session} />
                <td><IntegerView value={row.population_presac} /></td>
                <td><IntegerView value={row.population_sac} /></td>
                <td><IntegerView value={row.population_adult} /></td>
                <td>{row.endemicity_lf}</td>
                <td>{row.endemicity_oncho}</td>
                <td>{row.rounds_lf}</td>
                <td>{row.rounds_oncho}</td>
                <td>{row.surveys_lf_tas ? 'Yes' : 'No'}</td>
                <td>{row.surveys_oncho ? 'Yes' : 'No'}</td>
                <td><IntegerView value={row.target_pop_village_oncho} /></td>
                <td><IntegerView value={row.ivm_stock_remaining} /></td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
      <Pager count={count} pager={paging} setOffset={o => setPaging(p => ({...p, offset: o}))} />
    </div>
  )
}
