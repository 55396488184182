import {QueryApplicationsFilters, PagingParams, SortingInfo} from '../api';
import {ChangeEvent, KeyboardEvent, useState} from 'react';
import {
  SortAlphaDown, 
  SortAlphaUpAlt, 
  SortNumericDown, 
  SortNumericUpAlt,
  FilterIcon
} from '../components/icons';


export interface GenericSearchTableHeaderParams<T> {
  title: string
  prop: Extract<keyof T, string>
  filter?: QueryApplicationsFilters
  setFilter: (prop: Extract<keyof T, string>, val: string) => void
  paging: PagingParams
  changeSort: (prop: string) => void
  sortInfo: SortingInfo
  colSpan?: number
  rowSpan?: number
}


export function GenericSearchTableHeader<T>(
  {
    title,
    prop,
    filter,
    setFilter,
    paging,
    changeSort,
    sortInfo,
    colSpan,
    rowSpan
  } : GenericSearchTableHeaderParams<T>
): JSX.Element {

  const [text, setText] = useState('')


  function onKeyUp(key: KeyboardEvent<HTMLInputElement>) {
    if (key.key === 'Enter') {
      setFilter(prop, text)
    }
  }

  const sortElement: JSX.Element = paging.sort === prop
    ? (
      sortInfo[prop].dataType === 'alpha'
        ? paging.order === 'asc' ? <SortAlphaDown /> : <SortAlphaUpAlt />
        : paging.order === 'asc' ? <SortNumericDown /> : <SortNumericUpAlt />
    ) : <></>

  // @ts-ignore
  const filterIndicator = filter && filter[prop] && filter[prop].length > 0 && <FilterIcon />

  return (
    <th scope="col" 
      onClick={() => changeSort(prop)}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      <div className="table-col-title pb-2" 
        role="button"
        title="Click to sort"
      >
        {title}
        &nbsp;
        {filterIndicator}
        {sortElement}
      </div>
      <input
        className="form-control form-control-sm"
        onKeyUp={e => onKeyUp(e)}
        onClick={(e) => { e.stopPropagation() }}
        value={text}
        onChange={e => setText(e.target.value)} />
    </th>
  )
}
